import {Nullable, OutgoingCallType, TimeTrackingEntry as SdkTimeTrackingEntry, UserRole} from "@sense-os/goalie-js";
import {TIME} from "constants/time";
import moment from "moment";
import {padNumWithZero} from "../helpers/textTransform";

import {activities, activityTypes} from "./activityConfig";
import {ActivityKey, ActivityTypeKey, ConfirmationScreenItem, DateString, TimeTrackingEntry} from "./timeTrackingTypes";
import Localization, {ILocalization} from "../localization/Localization";
import {UserUtils} from "utils/UserUtils";
import {Contact} from "../contacts/contactTypes";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {CallDirection} from "services/chat/video/CallDirection";
import featureFlags from "../featureFlags/FeatureFlags";
import strTranslation from "../assets/lang/strings";

const loc: ILocalization = Localization;
/**
 * Format of `DateString` type.
 * @See src/timeTracking/timeTrackingTypes.ts
 */
export const DATE_FORMAT = "YYYY-MM-DD";

/**
 * Returns today's date in `DateString` format
 */
export function getTodaysDate(): DateString {
	return moment(Date.now()).format(DATE_FORMAT);
}

/** Returns true if the given date and today's date are the same */
export function isItToday(date: DateString): boolean {
	return date === getTodaysDate();
}

/**
 * Returns today's date in `DateString` format
 */
export function getYesterdaysDate(): DateString {
	return moment(Date.now()).subtract(1, "d").format(DATE_FORMAT);
}

/**
 * Returns two `Date` objects, representing the beginning and the end of a provided date.
 */
export function getDateBorders(date: DateString): {start: Date; end: Date} {
	const momentDate = dateStringToMoment(date);
	return {
		start: momentDate.startOf("date").toDate(),
		end: momentDate.endOf("date").toDate(),
	};
}
/**
 * Returns a given date in `DateString` format
 */
export function dateToString(date: Date): DateString {
	return moment(date).format(DATE_FORMAT);
}

/**
 * Converts a `DateString` value to a `Moment` object
 * @param date
 */
export function dateStringToMoment(date: DateString): moment.Moment {
	return moment(date, DATE_FORMAT);
}

// Build a date object from the given date string and time string.
export function getDateFromDateAndTimeString(dateString: string, timeString: string): Date {
	if (!isValidTimeString(timeString)) {
		return null;
	}

	const [startHour, startMinute] = timeString.split(":");
	const date = dateStringToMoment(dateString).toDate();
	date.setHours(Number(startHour || ""));
	date.setMinutes(Number(startMinute || ""));
	date.setSeconds(0);
	return date;
}

// Build a date object from the given date object and date string.
export function changeDateToFollowDateString(initialDate: Date, dateString: string): Date {
	if (!initialDate) {
		return null;
	}

	return dateStringToMoment(dateString)
		.hour(initialDate.getHours())
		.minute(initialDate.getMinutes())
		.second(initialDate.getSeconds())
		.toDate();
}

/**
 * Shifts the date by one day
 * @param date a date in `DateString` format
 * @param direction "-1" to go one day into the past, "+1" to go one day into the past,
 * @param maxOutToday Prevents going into the future (to tomorrow and later),
 * 					  i.e. if true and `date` points to `today` and `direction === "+1"`,
 * 					  the initial value of `date` will be returned.
 */
export function shiftDate(date: DateString, direction: "-1" | "+1", maxOutToday: boolean = true): DateString {
	const momentDate = dateStringToMoment(date);
	// Don't go to the future if we're not supposed to
	if (direction === "+1" && maxOutToday && getTodaysDate() === date) {
		return date;
	}

	direction === "-1" && momentDate.subtract(1, "d");
	direction === "+1" && momentDate.add(1, "d");
	return moment(momentDate).format(DATE_FORMAT);
}

//  TODO: make a function for comparing two `DateString` objects (Moment.js?)

// Get the list to populate the activity dropdown.
export const getActivityOptions = () => {
	return Object.values(ActivityKey);
};

// To map each activity key to its string value.
export const activityOptionsToValue = (key: ActivityKey) => {
	if (key) return activities[key]?.name || null;
	return null;
};

/** Get the list to populate the activity type dropdown. */
export const getTypeOptions = (activityKey?: ActivityKey) => {
	if (!activityKey) {
		return [];
	}

	const selectedActivity = activities[activityKey];
	return Object.keys(selectedActivity.types);
};

// To map each activity type key to its string value.
export const typeOptionsToValue = (key: ActivityTypeKey) => {
	if (key) return activityTypes[key]?.name || null;
	return null;
};

function isValidHour(hour: number): boolean {
	return !isNaN(hour) && hour >= 0 && hour <= 23;
}

function isValidMinute(minute: number): boolean {
	return !isNaN(minute) && minute >= 0 && minute <= 59;
}

export function isValidTimeString(text: string): boolean {
	if (!text) return false;
	const hasTimeSeparator = text.indexOf(":") > -1;

	if (hasTimeSeparator) {
		const [hourText, minuteText] = text.split(":");
		if (!isValidHour(Number(hourText)) || !isValidMinute(Number(minuteText))) {
			return false;
		}

		return true;
	}

	// Here we assume that the first two chars is the `hour` value
	if (text.length <= 2 && isValidHour(Number(text))) {
		return true;
	}

	// If there's no ":" and there're more than two chars,
	// it's not a valid time string
	return false;
}

export function isValidDurationString(text: string): boolean {
	if (!text) return false;
	const tokens = text.split(":");

	if (tokens.length > 3) return false;
	return (
		isValidHour(Number(tokens[0] || "")) &&
		isValidMinute(Number(tokens[1] || "")) &&
		isValidMinute(Number(tokens[2] || ""))
	);
}

// Transform seconds to duration string
export function getDurationStringFromSecond(value: number): string {
	const hour = Math.floor(value / TIME.SECONDS_IN_HOUR);
	const minute = Math.floor((value % TIME.SECONDS_IN_HOUR) / TIME.SECONDS_IN_MINUTE);
	const second = Math.floor(value % TIME.SECONDS_IN_MINUTE);

	return `${hour}:${padNumWithZero(minute, 2)}:${padNumWithZero(second, 2)}`;
}

// Get a formatted duration string between given two dates
export function getDurationBetweenDates(startDate: Date, endDate: Date): string {
	if (!startDate || !endDate) return "";

	try {
		const duration = Math.floor((endDate.getTime() - startDate.getTime()) / TIME.MILLISECONDS_IN_SECOND);
		if (duration <= 0) return getDurationStringFromSecond(0);
		return getDurationStringFromSecond(duration);
	} catch {
		// If any error happens, assume that either startDate or endDate is invalid and return zero.
		return getDurationStringFromSecond(0);
	}
}

// Get a formatted time string of a date object.
export function getTimeStringOfDate(value: Date): string {
	if (!value) return "";

	const hour = value.getHours();
	const minute = value.getMinutes();

	return `${padNumWithZero(hour, 2)}:${padNumWithZero(minute, 2)}`;
}

export function durationStringToSecond(timeText: string): number {
	if (!isValidDurationString(timeText)) {
		return 0;
	}

	const tokens = timeText.split(":");
	return (
		Number(tokens[0] || "") * TIME.SECONDS_IN_HOUR +
		Number(tokens[1] || "") * TIME.SECONDS_IN_MINUTE +
		Number(tokens[2] || "")
	);
}

// Transform a time string to seconds of the day.
// Useful to do a calculation between time string.
export function timeStringToSecond(timeString: string): number {
	if (!isValidTimeString(timeString)) return 0;
	const [hourText, minuteText] = timeString.split(":");
	return Number(hourText || "") * TIME.SECONDS_IN_HOUR + Number(minuteText || "") * TIME.SECONDS_IN_MINUTE;
}

// Get startTime from given endTime and duration string
export function getStartTime(endTime: Date, duration: string): Date {
	const seconds = durationStringToSecond(duration);
	const date = moment(endTime);

	date.subtract(seconds, "seconds");
	return date.toDate();
}

// Get endTime from given startTime and duration string
export function getEndTime(startTime: Date, duration: string): Date {
	const seconds = durationStringToSecond(duration);
	const date = moment(startTime);

	date.add(seconds, "seconds");
	return date.toDate();
}

/**
 * Transform a time entry from the format that was given by goalie-js
 * to the format that is used in portal.
 */
export function processTimeTrackingEntry(entry: SdkTimeTrackingEntry): TimeTrackingEntry {
	return {
		...entry,
		activityKey: entry.activity as ActivityKey,
		typeKey: entry.type as ActivityTypeKey,
		isAutoTracked: entry.isAutoTracked,
		isConfirmed: !!entry.confirmedAt,
		treatmentId: entry.treatment?.id,
		patient: entry.treatment ? {...entry.treatment.patient, treatmentId: entry.treatment.id} : null,
	};
}

/**
 * Transform time entries from the format that was given by goalie-js
 * to the format that is used in portal.
 */
export function processTimeTrackingEntries(entries: SdkTimeTrackingEntry[]): TimeTrackingEntry[] {
	return entries.map((entry) => processTimeTrackingEntry(entry));
}

/**
 * Return a sorted version of given time entries.
 * They are sorted by the start time, where an entry with no start time
 * will be placed as if it got `0` as the start time.
 */
export function getSortedTimeEntries(entries: TimeTrackingEntry[]): TimeTrackingEntry[] {
	const copyOfEntries = [...entries];

	// Resort the list of the modified date.
	copyOfEntries.sort((a, b) => {
		const x = a.startTime?.getTime() || 0;
		const y = b.startTime?.getTime() || 0;

		return x - y;
	});

	return copyOfEntries;
}

/*
 * Checks whether `entry` contains all the values passed as `values`.
 *
 * @param entry
 * @param values
 */
export function entryContainsValues(
	entry: TimeTrackingEntry,
	values: {
		activityKey: ActivityKey;
		typeKey: ActivityTypeKey;
		treatmentId: Nullable<number>;
		therapistId: Nullable<number>;
	},
): boolean {
	const {activityKey, typeKey, treatmentId, therapistId} = values;

	return (
		entry?.activityKey === activityKey &&
		entry?.typeKey === typeKey &&
		(entry?.treatmentId ?? null) === (treatmentId ?? null) &&
		(entry?.therapistId ?? null) === (therapistId ?? null)
	);
}

/**
 * Creates lists of Time Tracking Entries per client.
 * @param entries
 */
function groupEntriesPerClient(entries: TimeTrackingEntry[]): Record<number, TimeTrackingEntry[]> {
	const result: Record<number, TimeTrackingEntry[]> = {};
	entries.forEach((entry) => {
		const {treatmentId} = entry;
		if (!result[treatmentId]) {
			result[treatmentId] = [];
		}
		result[treatmentId].push(entry);
	});
	return result;
}

/**
 * Converts Time Tracking Entries into `ConfirmationScreenItem` shape,  which is suitable for rendering
 * the day confirmation dialog.
 * @param entries
 * @param treatmentIdToClientNameFn
 */
export function entriesToConfirmationItems(entries: TimeTrackingEntry[]): ConfirmationScreenItem[][] {
	const groupedEntries = groupEntriesPerClient(entries);
	const clientIds = Object.keys(groupedEntries);

	const result: ConfirmationScreenItem[][] = [];

	clientIds.forEach((clientId) => {
		//
		const entries: TimeTrackingEntry[] = groupedEntries[clientId];
		//
		// convert entries to renderable screen items for the current client
		const clientItems: ConfirmationScreenItem[] = entries.map((entry) => {
			return {
				key: "item#" + entry.id,
				clientName: entry.patient
					? UserUtils.getName(entry.patient.firstName, entry.patient.lastName, "")
					: null,
				startTime: getTimeStringOfDate(entry.startTime),
				duration: getDurationBetweenDates(entry.startTime, entry.endTime),
				endTime: getTimeStringOfDate(entry.endTime),
				activity: activityOptionsToValue(entry.activityKey),
				durationSeconds: (entry.endTime.valueOf() - entry.startTime.valueOf()) / TIME.MILLISECONDS_IN_SECOND,
			} as ConfirmationScreenItem;
		});

		result.push(clientItems);
	});

	return result;
}

/**
 * Calculates how many seconds were spent on all the given `entries` combined.
 * @param entries
 */
export function totalSeconds(entries: TimeTrackingEntry[]): number {
	const totalDurationMS: number = entries.reduce(
		(sum, entry) => sum + entry.endTime.valueOf() - entry.startTime.valueOf(),
		0,
	);

	return Math.round(totalDurationMS / TIME.MILLISECONDS_IN_SECOND);
}

/**
 * Returns true if the given Time Tracking Entry is eligible to be shown in the Day Confirmation Dialog.
 * @param entry
 */
export function validateConfirmationScreenItem(entry: TimeTrackingEntry): boolean {
	// time information is in the place
	const validTimeFields: boolean = !!entry.startTime && !!entry.endTime;

	// client ID is required only in case of direct or indirect care
	const isClientRequired: boolean =
		entry.typeKey === ActivityTypeKey.CARE_DIRECT || entry.typeKey === ActivityTypeKey.CARE_INDIRECT;

	// client is present if required
	const clientInOrder: boolean = isClientRequired ? !!entry.treatmentId : true;

	// in addition to validations the entry should still be unconfirmed
	return validTimeFields && clientInOrder && !entry.isConfirmed;
}

/**
 * Turns given seconds (`totalDurationSeconds`) into a localised human-readable string.
 * @param totalDurationSeconds
 */
export function getTotalDurationString(totalDurationSeconds: number): string {
	// Turn `totalDurationSeconds` into nicely formatted localised string
	const tempDate = moment().startOf("day").seconds(totalDurationSeconds).toDate();
	const hours = tempDate.getHours();
	const minutes = tempDate.getMinutes(); // const seconds = tempDate.getSeconds();
	return (
		`${hours} ${loc.formatMessage(strTranslation.TIME.hour.plural, {count: hours})} ${loc.formatMessage(
			strTranslation.COMMON.and,
		)}` +
		` ${minutes} ${loc.formatMessage(strTranslation.TIME.minute.plural, {count: minutes})}` + // + ` ${seconds} ${loc.formatMessage(strTranslation.TIME.second.plural", {count: seconds})} `;
		` ${loc.formatMessage(strTranslation.TIME_TRACKING.registered)}`
	);
}

const CALL_GAP_THRESHOLD = 5 * 60 * 1000; // 5 minutes

/**
 * This is the function that will check whether two session entries should be merged as one.
 */
export function canTwoSessionEntriesBeMerged(
	firstEntry: Partial<TimeTrackingEntry>,
	secondEntry: Partial<TimeTrackingEntry>,
) {
	// First check whether any of the entries
	// isn't about session.
	if (!firstEntry.note?.callData || !secondEntry.note?.callData) return false;

	// Prevent merging if one of the entry is already confirmed.
	if (firstEntry.isConfirmed || secondEntry.isConfirmed) return false;

	// Prevent merging if one of them isn't automatically created.
	if (!firstEntry.isAutoTracked || !secondEntry.isAutoTracked) return false;

	// Entries can only be merged if either they have the same roomId,
	// or they have the same clientId.
	if (
		firstEntry.note.callData.roomId !== secondEntry.note.callData.roomId &&
		firstEntry.note.callData.clientId !== secondEntry.note.callData.clientId
	)
		return false;

	// Entries can also only be merged if they have the same activity and type.
	if (firstEntry.activityKey !== secondEntry.activityKey || firstEntry.typeKey !== secondEntry.typeKey) return false;

	// Entries can only be merged if they are separated less than this threshold.
	const endTimeOfEarlierEntry = Math.min(firstEntry.endTime.getTime(), secondEntry.endTime.getTime());
	const startTimeOfLaterEntry = Math.max(firstEntry.startTime.getTime(), secondEntry.startTime.getTime());

	return startTimeOfLaterEntry - endTimeOfEarlierEntry <= CALL_GAP_THRESHOLD;
}

/**
 * This is the function that will return activity key by given active call, call initiator and call type
 */
export function getActivityKey(
	activeCall: ActiveCall,
	callInitiator: Contact,
	callType: OutgoingCallType,
): ActivityKey {
	let selectedCallType = callType;
	const isConferenceCall = activeCall?.isConferenceCall;
	const isOutgoingCallTypeEnabled = featureFlags.outgoingCallType;

	// Only track conference call if active call is `conference call` and `outgoingCallType` flag is disabled
	const isConferenceCallTracked = isConferenceCall && !isOutgoingCallTypeEnabled;

	if (activeCall?.direction === CallDirection.INCOMING) {
		if (isConferenceCallTracked) {
			// Set it as `colleague` if `conference call` is tracked
			selectedCallType = OutgoingCallType.COLLEAGUE;
		} else {
			if (callInitiator?.role === UserRole.PATIENT) {
				// We want to set incoming call from client to be tracked
				// in auto time tracking as a `session`
				selectedCallType = OutgoingCallType.SESSION;
			} else {
				// Set incoming call from other therapist to be tracked
				// in auto time tracking as a `colleague`
				selectedCallType = OutgoingCallType.COLLEAGUE;

				if (isOutgoingCallTypeEnabled && isConferenceCall) {
					// Set incoming call as `mdo` only if `outgoingCallType` flag is enabled
					// and it's a conference call
					selectedCallType = OutgoingCallType.MDO;
				}
			}
		}
	}

	if (selectedCallType === OutgoingCallType.COLLEAGUE) {
		Object.values(activeCall?.participantMap).forEach((participant) => {
			if (participant.role === UserRole.PATIENT) {
				// We want to set outgoing call to colleague to be tracked
				// in auto time tracking as a `session` if there is a client in it.
				selectedCallType = OutgoingCallType.SESSION;
			}
		});
	}

	switch (selectedCallType) {
		case OutgoingCallType.SESSION:
			// Session is registered as a session
			return ActivityKey.ONLINE_CONSULT;

		case OutgoingCallType.INTAKE:
			// Intake is registered as an intake
			return ActivityKey.ONLINE_INTAKE;

		case OutgoingCallType.EMDR:
			// EMDR is registered as a session
			return ActivityKey.ONLINE_CONSULT;

		case OutgoingCallType.COLLEAGUE:
			/**
			 * As of 5 Oct 2023, we no longer make a time entry without client,
			 * that's why we return null here.
			 *
			 * This return here assume that the logic above this part
			 * will correctly won't branch out to this part
			 * if the call has a client.
			 */
			return null;

		case OutgoingCallType.MDO:
			// Therapists group call is not tracked.
			return null;
	}
}
