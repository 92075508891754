import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

import {AutoTrackedActivity, DateString, TimeTrackingEntry, TimeTrackingSettings} from "../timeTrackingTypes";
import {ActiveCall} from "services/chat/video/ActiveCall";
import {wrapInDispatch} from "../../helpers/wrapInDispatch";
import {OutgoingCallType} from "@sense-os/goalie-js";

export const timeTrackingActions = {
	// This is the actions that will be dispatched by timeTrackingNavigationSaga.
	fetchEntries: createAsyncAction(
		"timeTrackingActions/fetchEntries",
		"timeTrackingActions/fetchEntriesDidSucceed",
		"timeTrackingActions/fetchEntriesDidFail",
	)<{date: DateString}, {entries: TimeTrackingEntry[]; date: DateString}, {error: Error; date: DateString}>(),

	fetchUnconfirmedEntries: createAsyncAction(
		"timeTrackingActions/fetchUnconfirmedEntries",
		"timeTrackingActions/fetchUnconfirmedEntriesSucceed",
		"timeTrackingActions/fetchUnconfirmedEntriesFail",
	)<void, {entries: TimeTrackingEntry[]}, {error: Error}>(),

	addUnconfirmedEntry: createAction("timeTrackingActions/addUnconfirmedEntry", (entry: TimeTrackingEntry) => ({
		entry,
	}))(),

	fetchEntriesForCalendarIfNeeded: createAction(
		"timeTrackingActions/fetchEntriesForCalendarIfNeeded",
		(year: number, month: number) => ({year, month}),
	)(),

	/**
	 * This action is used simply to store the entry payload from user settings to redux.
	 * Storing the payload to redux, in return, will trigger the UI to rerender the input
	 * and show the stored payload. Useful when loading the input value the first
	 * time portal's tab is loaded.
	 */
	storeActiveEntryInputToRedux: createAction(
		"timeTrackingActions/storeActiveEntryInputToRedux",
		(entry: Partial<TimeTrackingEntry>, isTimerRunning: boolean) => ({
			entry,
			isTimerRunning,
		}),
	)(),

	/**
	 * This action will reset the input entry and empty the rendered input.
	 */
	resetActiveEntry: createAction("timeTrackingActions/resetActiveEntry")(),

	/**
	 * This action will reset the input entry, empty the rendered input,
	 * and stop the running time ticker if neeeded.
	 */
	resetActiveEntryAndStopTimer: createAction("timeTrackingActions/resetActiveEntryAndStopTimer")(),

	/**
	 * This action should be dispatched whenever we update the active entry input.
	 * This action will trigger the process where portal store this updated entry
	 * to BE so that it can persists between sessions.
	 */
	updateActiveEntry: createAction("timeTrackingActions/updateActiveEntry", (entry: Partial<TimeTrackingEntry>) => ({
		entry,
	}))(),

	/** This is the action that is fired whenever portal want to store time tracking settings into BE */
	storeTimeTrackingSettings: createAction(
		"timeTrackingActions/storeTimeTrackingSettings",
		(settings: TimeTrackingSettings) => ({settings}),
	)(),

	/** This action will set the flag of whether user allow portal to automatically create time entry */
	setDoesUserAllowAutoTracking: createAction(
		"timeTrackingActions/setDoesUserAllowAutoTracking",
		(doesUserAllowAutoTracking: boolean) => ({doesUserAllowAutoTracking}),
	)(),

	/**
	 * The action to set the flag of whether the timer for active entry is running or not.
	 */
	setIsTimerRunningForActiveEntry: createAction(
		"timeTrackingActions/setIsTimerRunningForActiveEntry",
		(isTimerRunning: boolean) => ({
			isTimerRunning,
		}),
	)(),

	// These actions are dispatched as part of navigation to different days
	goToPrevDay: createAction("timeTrackingActions/goToPrevDay")(),
	goToNextDay: createAction("timeTrackingActions/goToNextDay")(),
	goToToday: createAction("timeTrackingActions/goToToday")(),
	goToDate: createAction("timeTrackingActions/goToDate", (date: Date) => date)(),
	setDate: createAction("timeTrackingActions/setDate", (date: DateString) => ({date}))(),

	// The next few async actions are for the CRUD operations of time tracking entries.
	createTimeEntry: createAsyncAction(
		"timeTrackingActions/createEntryForManualTracking",
		"timeTrackingActions/createEntryForManualTrackingDidSucceed",
		"timeTrackingActions/createEntryForManualTrackingDidFail",
	)<{entry: Partial<TimeTrackingEntry>}, {entry: TimeTrackingEntry}, {error: Error}>(),

	updateEntry: createAsyncAction(
		"timeTrackingActions/updateEntry",
		"timeTrackingActions/updateEntryDidSucceed",
		"timeTrackingActions/updateEntryDidFail",
	)<
		{entryId: number; entry: Partial<TimeTrackingEntry>; date: DateString},
		{entryId: number; entry: TimeTrackingEntry; date: DateString},
		{entryId: number; error: Error}
	>(),

	deleteEntry: createAsyncAction(
		"timeTrackingActions/deleteEntry",
		"timeTrackingActions/deleteEntryDidSucceed",
		"timeTrackingActions/deleteEntryDidFail",
	)<{date: DateString; entryId: number}, {date: DateString; entryId: number}, {entryId: number; error: Error}>(),

	/**
	 * Starts automated recording of time for a chat entry.
	 */
	startTrackingChat: createAction("timeTrackingActions/startTrackingChat", (userId: number) => ({userId}))(),

	/**
	 * Stops automated recording of time for a chat entry.
	 */
	stopTrackingChat: createAction("timeTrackingActions/stopTrackingChat", (userId: number) => ({userId}))(),

	/**
	 * 1. Stops automated recording of time for the currently recorded entry.
	 * 2. Completes the current entry by filling in missing values.
	 * The entry is kept in `currentlyTrackedEntry` in the state.
	 *
	 * If there's no currently recorded entry, this action has no effect.
	 */
	saveTrackedChat: createAction("timeTrackingActions/saveTrackedChat", (userId: number) => ({userId}))(),

	/**
	 * This is the action that will be fired when portal finally want to hit the BE
	 * to store the automated time entry. It is named "bulk" as this action can contain
	 * multiple entries.
	 */
	autoTrackingBulkPost: createAction(
		"timeTrackingActions/autoTrackingBulkPost",
		(newEntries: Partial<TimeTrackingEntry>[]) => ({newEntries}),
	)(),

	/**
	 * This is the one action that will be fired at the end of the call,
	 * to trigger the portal to start calculating and creating a time
	 * entry for that session.
	 */
	createTimeEntryForCallSession: createAction(
		"timeTrackingActions/createTimeEntryForCallSession",
		(call: ActiveCall, callType?: OutgoingCallType) => ({call, callType}),
	)(),

	/**
	 * Populates `currentAutoTrackedEntry` in the state
	 * that contains necessary information about the automatically tracked activity.
	 */
	setAutoTrackedActivity: createAction(
		"timeTrackingActions/setAutoTrackedActivity",
		(activity: AutoTrackedActivity) => ({activity}),
	)(),

	confirmEntries: createAsyncAction(
		"timeTrackingActions/confirmEntries",
		"timeTrackingActions/confirmEntriesSuccess",
		"timeTrackingActions/confirmEntriesFail",
	)<
		{entries: TimeTrackingEntry[]; date: DateString},
		{entries: TimeTrackingEntry[]; date: DateString},
		{error: Error}
	>(),

	/**
	 * Allows 'undeleting' an entry after deletion and before the deletion was requested at the backend.
	 */
	undoEntryDeletion: createAction("timeTrackingActions/undoEntryDeletion", (entryId: number) => ({entryId}))(),

	exportTimeTracking: createAsyncAction(
		"timeTrackingActions/exportTimeTracking",
		"timeTrackingActions/exportTimeTrackingSucceed",
		"timeTrackingActions/exportTimeTrackingFailed",
	)<
		{
			patient?: string[];
			periodAfter: Date;
			periodBefore: Date;
			activity?: string[];
			bundleChat?: boolean;
			onlyConfirmed?: boolean;
		},
		void,
		{error: Error}
	>(),

	getDownloadLinkofTimeTrackingExport: createAsyncAction(
		"timeTrackingActions/getDownloadLinkofTimeTrackingExport",
		"timeTrackingActions/getDownloadLinkofTimeTrackingExportSucceed",
		"timeTrackingActions/getDownloadLinkofTimeTrackingExportFailed",
	)<
		{downloadId: string},
		{downloadId: string; downloadName: string; downloadLink: string},
		{downloadId: string; error: Error}
	>(),

	// These actions is created to represent analytics.
	clickingExportButton: createAction("timeTrackingActions/clickingExportButton")(),
	clickingRequestExport: createAction("timeTrackingActions/clickingRequestExport")(),
	clickingDownloadExport: createAction("timeTrackingActions/clickingDownloadExport")(),
};

export type timeTrackingActionType = ActionType<typeof timeTrackingActions>;
export const timeTrackingActionsWithDispatch = wrapInDispatch(timeTrackingActions);
