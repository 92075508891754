import {Message, UserRole} from "@sense-os/goalie-js";
import {CallParticipantsInInterval} from "@sense-os/goalie-js/dist/chat/call";
import {delay, put, select, takeEvery, call as sagaCall, fork} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {AuthUser} from "../../auth/authTypes";
import {getAuthUser} from "../../auth/redux";
import {chatRoomMessages} from "../../chat/redux/ChatSelector";

import chatSDK from "../../chat/sdk";
import {Contact} from "../../contacts/contactTypes";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {checkAuthUserAccess, PortalFeatures} from "../../featureFlags/userFeatureAccess";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import {whenEnabled} from "../../helpers/sagas/whenEnabled";
import {getClientStatus} from "../../treatmentStatus/redux/treatmentStatusSelectors";
import {Treatment} from "../../treatmentStatus/treatmentStatusTypes";

import {timeTrackingActions} from "../redux/timeTrackingActions";
import {
	getCurrentAutoTrackedActivity,
	getDoesUserAllowAutoTracking,
	getTodayTrackingEntries,
} from "../redux/timeTrackingSelectors";
import {canTwoSessionEntriesBeMerged, dateToString, getTodaysDate, getActivityKey} from "../timeTrackingHelpers";
import {
	ActivityKey,
	ActivityTypeKey,
	AutoTrackedActivity,
	AutoTrackedActivityType,
	TimeTrackingEntry,
} from "../timeTrackingTypes";
import featureFlags from "../../featureFlags/FeatureFlags";

/**
 * This is the saga that will post to BE the automatically created time entry
 */
function* autoTrackBulkPost(action: ActionType<typeof timeTrackingActions.autoTrackingBulkPost>) {
	const entries = action.payload.newEntries;
	for (let i = 0; i < entries.length; i++) {
		const entry = entries[i];
		if (entry.id) {
			// If the entry already has an id, update the entry
			// instead of creating a new one.
			yield put(
				timeTrackingActions.updateEntry.request({
					entryId: entry.id,
					date: getTodaysDate(),
					entry: {endTime: entry.endTime},
				}),
			);
		} else if (!entry.note?.callData?.clientId) {
			// If the entry doesn't involve client, simply create it.
			yield put(timeTrackingActions.createTimeEntry.request({entry}));
		} else {
			// If the entry involves client, only create it if there're already
			// ongoing treatment for the client.
			const clientTreatment: Treatment = yield select(getClientStatus(entry.note.callData.clientId));

			const clientTreatmentId =
				!clientTreatment || !!clientTreatment.endTime ? entry.note.callData.clientId * -1 : clientTreatment.id;

			yield put(
				timeTrackingActions.createTimeEntry.request({
					entry: {
						...entry,
						treatmentId: clientTreatmentId,
					},
				}),
			);
		}

		// Add a little delay to lighten the load to BE
		yield delay(100);
	}
}

/**
 * This is the saga that contain the logic of what and when time entry for call session
 * should be created.
 */
function* createTimeEntryForCallSession(action: ActionType<typeof timeTrackingActions.createTimeEntryForCallSession>) {
	const {call, callType} = action.payload;

	const authUser: AuthUser = yield select(getAuthUser);
	const isAutomateTracking: boolean = yield select(getDoesUserAllowAutoTracking);
	const userHasAccess = checkAuthUserAccess(authUser)(PortalFeatures.autoTimeTracking);
	const callInitiator: Contact = yield select(getContactById, call?.initiatorUserId);

	const activityKey = yield sagaCall(getActivityKey, call, callInitiator, callType);

	// This saga won't do anything if the automated tracking is turned off.
	if (!call || !isAutomateTracking || !userHasAccess || !activityKey) return;

	const {roomId} = call;
	let rawParticipantsInIntervals: CallParticipantsInInterval[] = [];

	if (featureFlags.meetingAgora && featureFlags.meetingAgoraSdk) {
		const callParticipantMap = call.participantMap;

		rawParticipantsInIntervals = Object.keys(callParticipantMap).map((key) => {
			// TODO: update `startTime` and `endTime` with BE value
			return {
				startTime: callParticipantMap[key].joinedTime,
				endTime: callParticipantMap[key].leaveTime,
				participants: callParticipantMap,
			};
		});
	} else {
		rawParticipantsInIntervals = yield apiCallSaga(chatSDK.getCallParticipantsInIntervals, roomId);
	}

	// To make further operations with these intervals easier,
	// we add fields called participantIds and clientIds to them.
	const callIntervals = rawParticipantsInIntervals.map((interval) => {
		const {participants} = interval;
		const participantIds = Object.keys(participants).map((x) => parseInt(x, 10));
		const clientId = participantIds.find((id) => interval.participants[id].role === UserRole.PATIENT);

		return {...interval, clientId, participantIds};
	});

	// Interval with only one or less participant is irrelevant.
	// Interval without the logged in user is irrelevant.
	// Interval that ends before user join the call is irrelevant.
	const relevantIntervals = callIntervals.filter(({participants, participantIds, endTime}) => {
		return (
			participantIds.length >= 2 &&
			!!participants[authUser.id] &&
			endTime >= call.participantMap[authUser.id].initiatedTime
		);
	});

	// How the call will be translated into a time entry will
	// depends on whether a client ever joins the call or not.
	// We also assume that there will ever be maximum one client in the call.
	const intervalWithClient = callIntervals.find((interval) => !!interval.clientId);
	const clientOfTheCall = intervalWithClient?.clientId;

	const entryOfIntervals: Partial<TimeTrackingEntry>[] = relevantIntervals.map((interval) => {
		const {clientId} = interval;

		return {
			startTime: new Date(interval.startTime),
			endTime: new Date(interval.endTime),
			activityKey: activityKey,
			typeKey: (() => {
				if (!!clientId) return ActivityTypeKey.CARE_DIRECT;
				if (!!clientOfTheCall) return ActivityTypeKey.CARE_INDIRECT;
				return ActivityTypeKey.CONTACT_WITH_COLLEAGUES;
			})(),
			note: {callData: {roomId, clientId: clientId || clientOfTheCall}},
			isAutoTracked: true,
		};
	});

	const todayEntries: Partial<TimeTrackingEntry>[] = yield select(getTodayTrackingEntries);
	const reversedTodayEntries = [...todayEntries];
	reversedTodayEntries.reverse();

	const processedEntries = [];
	for (let idx = 0; idx < entryOfIntervals.length; idx++) {
		const entry = entryOfIntervals[idx];

		// Don't create entry with CARE_INDIRECT type that's less than 5 minutes
		if (
			entry.typeKey === ActivityTypeKey.CARE_INDIRECT &&
			entry.endTime.getTime() - entry.startTime.getTime() < 5 * 60 * 1000
		)
			continue;

		if (processedEntries.length === 0) {
			// const latestEntryNonChat = todayEntries[todayEntries.length - 1];

			// When merging session entries, any chat entries between the merged entries
			// can be safely deleted.
			const idxLatestEntryNonChat = reversedTodayEntries.findIndex((prevEntry) => {
				return (
					!prevEntry.isAutoTracked ||
					prevEntry.isConfirmed ||
					prevEntry.activityKey !== ActivityKey.DIRECT_TIME_ASYNC ||
					prevEntry.typeKey !== ActivityTypeKey.CARE_DIRECT ||
					!prevEntry.note?.chatData?.clientId ||
					entry.note.callData.clientId !== prevEntry.note?.chatData?.clientId
				);
			});

			const latestEntryNonChat = reversedTodayEntries[idxLatestEntryNonChat];
			if (!latestEntryNonChat || !canTwoSessionEntriesBeMerged(latestEntryNonChat, entry)) {
				processedEntries.push(entry);
			} else {
				const newEntry = {...latestEntryNonChat};
				newEntry.endTime = entry.endTime;
				processedEntries.push(newEntry);

				// Be sure to delete any chat entry between merged entries.
				for (let chatIdx = 0; chatIdx < idxLatestEntryNonChat; chatIdx++) {
					const deletedEntry = reversedTodayEntries[chatIdx];
					yield put(
						timeTrackingActions.deleteEntry.request({
							entryId: deletedEntry.id,
							date: dateToString(deletedEntry.startTime),
						}),
					);
				}
			}
		} else {
			if (canTwoSessionEntriesBeMerged(processedEntries[processedEntries.length - 1], entry)) {
				processedEntries[processedEntries.length - 1].endTime = entry.endTime;
			} else {
				processedEntries.push(entry);
			}
		}
	}

	yield put(timeTrackingActions.autoTrackingBulkPost(processedEntries));
}

function* startTrackingChat(action: ActionType<typeof timeTrackingActions.startTrackingChat>) {
	const {userId} = action.payload;

	// Let's check whether we already track an entry for the given action, if so, there's nothing else to do but return.
	const curEntry: AutoTrackedActivity = yield select(getCurrentAutoTrackedActivity);
	const curTime = new Date();

	// Don't start a new tracking entry when the previous entry is a chat ATT
	// and ends less than one minute ago.
	const preservePrevEntry =
		!!curEntry &&
		(curEntry.type === AutoTrackedActivityType.CHAT_WITH_PATIENT ||
			curEntry.type === AutoTrackedActivityType.CHAT_WITH_THERAPIST) &&
		curEntry.userId === userId &&
		(!curEntry.endTime || curTime.getTime() - curEntry.endTime.getTime() < 60000);

	// What to do when there's already a tracked activity in progress?
	if (preservePrevEntry) {
		// Create a copy of the same entry but without end time.
		yield put(
			timeTrackingActions.setAutoTrackedActivity({
				type: AutoTrackedActivityType.CHAT_WITH_PATIENT,
				userId,
				startTime: curEntry.startTime,
			}),
		);
	} else {
		// We put the startTime a bit earlier to also takes into account the time
		// the therapist takes to read client's messages, with speed assumed around 30 characters/second.
		// But make sure that it doesn't overlap with previous entry.
		const todayEntries: Partial<TimeTrackingEntry>[] = yield select(getTodayTrackingEntries);
		const lastEntry = todayEntries[todayEntries.length - 1];
		const minimumStartTime = lastEntry ? lastEntry.endTime.getTime() : 0;

		const authUser: AuthUser = yield select(getAuthUser);
		const ownId = authUser.id;

		const chatMessages: Message[] = yield select(chatRoomMessages(userId));
		const reversedChats = [...chatMessages];
		reversedChats.reverse(); // Reverse so the recent messages is in the first index.
		const lastSentMessageIdx = reversedChats.findIndex((message) => message.from === ownId);
		const totalReadCharacters = reversedChats.slice(0, lastSentMessageIdx).reduce((accum, message) => {
			return accum + message.content.TEXT?.length;
		}, 0);
		const totalTimeReadingInMS = Math.floor((totalReadCharacters * 1000) / 30); // Assume speed reading 30 chars/second

		const calculatedStartTime = Math.max(minimumStartTime, curTime.getTime() - totalTimeReadingInMS - 10000);

		yield put(
			timeTrackingActions.setAutoTrackedActivity({
				type: AutoTrackedActivityType.CHAT_WITH_PATIENT,
				userId,
				startTime: new Date(calculatedStartTime),
			}),
		);
	}
}

function* stopTrackingChat(action: ActionType<typeof timeTrackingActions.stopTrackingChat>) {
	const {userId} = action.payload;

	// When stopping automated tracking, we simply add `endTime` to the stored information
	// instead of deleting it. This is done that way as there are possibility that
	// we want to reuse past tracking information for next tracking.
	const curEntry: AutoTrackedActivity = yield select(getCurrentAutoTrackedActivity);

	// Don't stop if the currently tracked user doesn't match with the payload data.
	// Don't stop if the currently tracked activity is not chat related.
	// Don't stop if it's already stopped.
	if (
		!curEntry ||
		!!curEntry.endTime ||
		curEntry.userId !== userId ||
		(curEntry.type !== AutoTrackedActivityType.CHAT_WITH_PATIENT &&
			curEntry.type !== AutoTrackedActivityType.CHAT_WITH_THERAPIST)
	) {
		return;
	}

	yield put(
		timeTrackingActions.setAutoTrackedActivity({
			...curEntry,
			endTime: new Date(),
		}),
	);
}

function* saveTrackedChat(action: ActionType<typeof timeTrackingActions.saveTrackedChat>) {
	const {userId} = action.payload;
	const curEntry: AutoTrackedActivity = yield select(getCurrentAutoTrackedActivity);
	const isAutoTrackAllowed: boolean = yield select(getDoesUserAllowAutoTracking);

	// Don't do anything if the userId doesn't match
	if (!isAutoTrackAllowed || !curEntry || userId !== curEntry.userId) {
		return;
	}

	const clientTreatment: Treatment = yield select(getClientStatus(userId));
	const clientTreatmentId = !clientTreatment || !!clientTreatment.endTime ? userId * -1 : clientTreatment.id;

	const todayEntries: Partial<TimeTrackingEntry>[] = yield select(getTodayTrackingEntries);
	const lastEntry = todayEntries[todayEntries.length - 1];

	// Make sure that the created entry didn't overlap with previous one.
	const startTime = !lastEntry
		? curEntry.startTime
		: new Date(Math.max(curEntry.startTime.getTime(), lastEntry.endTime.getTime()));

	// To be safe, discount one second from the endTime, but make sure that it's not earlier
	// than startTime.
	const endTime = new Date(Math.max(new Date().getTime() - 10000, startTime.getTime()));

	const trackedEntry: Partial<TimeTrackingEntry> = {
		treatmentId: clientTreatmentId,
		activityKey: ActivityKey.DIRECT_TIME_ASYNC,
		typeKey: ActivityTypeKey.CARE_DIRECT,
		startTime,
		endTime,
		isAutoTracked: true,
		note: {chatData: {clientId: userId}},
	};

	// Merge with previous entry if possible
	if (
		!!lastEntry &&
		lastEntry.activityKey === trackedEntry.activityKey &&
		lastEntry.typeKey === trackedEntry.typeKey &&
		lastEntry.treatmentId === trackedEntry.treatmentId &&
		lastEntry.endTime >= trackedEntry.startTime &&
		!lastEntry.isConfirmed &&
		lastEntry.isAutoTracked
	) {
		yield put(
			timeTrackingActions.updateEntry.request({
				entryId: lastEntry.id,
				date: getTodaysDate(),
				entry: {endTime: trackedEntry.endTime},
			}),
		);
	} else {
		yield put(timeTrackingActions.createTimeEntry.request({entry: trackedEntry}));
	}
}

function* automaticTimeTrackingSaga() {
	yield takeEvery(getType(timeTrackingActions.autoTrackingBulkPost), autoTrackBulkPost);
	yield takeEvery(getType(timeTrackingActions.createTimeEntryForCallSession), createTimeEntryForCallSession);

	yield takeEvery(getType(timeTrackingActions.startTrackingChat), startTrackingChat);
	yield takeEvery(getType(timeTrackingActions.stopTrackingChat), stopTrackingChat);
	yield takeEvery(getType(timeTrackingActions.saveTrackedChat), saveTrackedChat);
}

export default function* () {
	yield fork(whenEnabled(PortalFeatures.autoTimeTracking, automaticTimeTrackingSaga));
}
