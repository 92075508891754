import chatSDK from "../../chat/sdk";
import {TerminationReason, CallSignal, CallSignalType, UserRole, OutgoingCallType} from "@sense-os/goalie-js";
import {States} from "services/chat/video/States";
import storage from "services/system/storage/Storage";
import {StorageKeys} from "services/system/storage/StorageKeys";
import {ActiveCall} from "services/chat/video/ActiveCall";
import featureFlags from "../../featureFlags/FeatureFlags";

export function sendBusyTerminationSignal(roomId: string) {
	return chatSDK.terminateCall(roomId, TerminationReason.Busy);
}

export function getCallStateFromStorage() {
	const callState: States = storage.readFromLocalStorage<States>(StorageKeys.VIDEO_CALL_STATE);
	return callState;
}

export function setCallState(state: States) {
	storage.write(StorageKeys.VIDEO_CALL_STATE, state);
}

/**
 * Masks `CallSignal` object to not expose sensitive informations
 * @param signal
 */
export function sentrySafeCallSignal(signal: CallSignal) {
	let sentrySafeData: any = {
		type: signal.type,
		status: signal.callStatus,
		roomId: signal.roomId,
	};
	if (signal.type === CallSignalType.Initiation) {
		sentrySafeData.availableCallSDKs = signal.availableCallSDKs;
	} else if (signal.type === CallSignalType.Proceed) {
		sentrySafeData.selectedCallSDK = signal.selectedCallSDK;
		sentrySafeData.deviceInformation = signal.deviceInformation;
	} else if (signal.type === CallSignalType.Termination) {
		sentrySafeData.terminationReason = signal.terminationReason;
	}
	return sentrySafeData;
}

/**
 * Returns true if portal should send cancellation signal.
 * If the function returns false, then we should send termination signal
 *
 * @param {ActiveCall} activeCall
 * @param {number} localUserId
 */
export function shouldSendCancelSignal(activeCall: ActiveCall, localUserId: number): boolean {
	if (!activeCall) {
		return false;
	}

	const participantIds = Object.keys(activeCall.participantMap);

	// If participants is more than two, we don't cancel. We terminate.
	if (participantIds.length > 2) {
		return false;
	}

	// If there are only two participants, check whether the initiator is localuser,
	// and localUser already joined and the other party is not.
	const luParticipantData = activeCall.participantMap[localUserId];
	const remoteParticipantId = participantIds.find((id) => id !== localUserId.toString());
	const remoteParticipantData = activeCall.participantMap[remoteParticipantId];

	return (
		activeCall.initiatorUserId === localUserId && // localUser is the call initiator
		!!luParticipantData.joinedTime && // localUser has joined the call
		!remoteParticipantData.joinedTime // remote participant has not joined the call
	);
}

/**
 * Returns call type list by given sender and recipient role
 */
export function getCallTypeListByParticipantsRole(senderRole: UserRole, recipientRole: UserRole): OutgoingCallType[] {
	let callTypes: OutgoingCallType[] = [];

	if (senderRole === UserRole.PATIENT) {
		// No need to set `callTypes` for outgoing call from `patient`
		callTypes = [];
	} else {
		if (recipientRole === UserRole.PATIENT) {
			// Set callTypes with `Session`, `Intake`, and `EMDR` for therapist calling a `patient`
			callTypes = [OutgoingCallType.SESSION, OutgoingCallType.INTAKE, OutgoingCallType.EMDR];
			if (!featureFlags.emdr) {
				// Remove EMDR if feature flag is disabled
				callTypes.pop();
			}
		} else {
			// Set callTypes with `Colleague`, and `MDO` for therapist calling other therapist
			callTypes = [OutgoingCallType.COLLEAGUE, OutgoingCallType.MDO];
		}
	}

	return callTypes;
}
