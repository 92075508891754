import produce from "immer";
import {getType} from "typesafe-actions";

import {LoadingState} from "constants/redux";
import {ReferralClientState} from "../referralClientTypes";
import {referralClientActions, ReferralClientActions} from "./referralClientAction";

const defaultState: ReferralClientState = {
	referralClientList: {
		fetchingState: LoadingState.EMPTY,
		data: [],
		total: 0,
		statusCount: null,
	},
	referralClientDetail: {
		fetchingState: LoadingState.EMPTY,
		data: null,
		id: null,
	},
	formDialog: {
		isOpen: false,
		savingState: LoadingState.EMPTY,
	},
	// Deprecated
	reviewTemplate: {
		fetchingState: LoadingState.EMPTY,
		id: null,
	},
	questionnaireReview: {
		savingState: LoadingState.EMPTY,
	},
	statusUpdateDialog: {
		id: null,
		isOpen: false,
		savingState: LoadingState.EMPTY,
		type: null,
		notes: "",
	},
	referralNotes: {
		id: null,
		savingState: LoadingState.EMPTY,
		notes: "",
		isOpen: false,
	},
	therapyType: {
		fetchingState: LoadingState.EMPTY,
		data: [],
	},
};

export const referralClientReducer = produce((state: ReferralClientState, action: ReferralClientActions) => {
	switch (action.type) {
		// fetch referral clients
		case getType(referralClientActions.fetchReferralClientList.request): {
			state.referralClientList.fetchingState = LoadingState.LOADING;
			break;
		}
		case getType(referralClientActions.fetchReferralClientList.success): {
			state.referralClientList.fetchingState = LoadingState.LOADED;
			state.referralClientList.data = action.payload.data;
			state.referralClientList.total = action.payload.total;
			state.referralClientList.statusCount = action.payload.statusCount;
			break;
		}
		case getType(referralClientActions.fetchReferralClientList.failure): {
			state.referralClientList.fetchingState = LoadingState.ERROR;
			break;
		}

		// fetch referral client detail
		case getType(referralClientActions.fetchReferralClientDetail.request): {
			state.referralClientDetail.fetchingState = LoadingState.LOADING;
			state.referralClientDetail.id = action.payload.id;
			break;
		}
		case getType(referralClientActions.fetchReferralClientDetail.success): {
			state.referralClientDetail.fetchingState = LoadingState.LOADED;
			state.referralClientDetail.data = action.payload.data;
			break;
		}
		case getType(referralClientActions.fetchReferralClientDetail.failure): {
			state.referralClientDetail.fetchingState = LoadingState.ERROR;
			break;
		}

		// open/close form dialog
		case getType(referralClientActions.setOpenFormDialog): {
			state.formDialog.isOpen = action.payload.value;
			state.formDialog.savingState = LoadingState.EMPTY;
			break;
		}

		// register referral client
		case getType(referralClientActions.createReferralClient.request): {
			state.formDialog.savingState = LoadingState.LOADING;
			break;
		}
		case getType(referralClientActions.createReferralClient.success): {
			state.formDialog.savingState = LoadingState.LOADED;
			state.formDialog.isOpen = false;
			break;
		}
		case getType(referralClientActions.createReferralClient.failure): {
			state.formDialog.savingState = LoadingState.ERROR;
			break;
		}

		// fetch review template
		case getType(referralClientActions.fetchReviewTemplate.request): {
			state.reviewTemplate.fetchingState = LoadingState.LOADING;
			state.reviewTemplate.id = action.payload.id;
			break;
		}
		case getType(referralClientActions.fetchReviewTemplate.success): {
			state.reviewTemplate.fetchingState = LoadingState.LOADED;
			break;
		}
		case getType(referralClientActions.fetchReviewTemplate.failure): {
			state.reviewTemplate.fetchingState = LoadingState.ERROR;
			break;
		}

		// create questionnaire review
		case getType(referralClientActions.createQuestionnaireReview.request): {
			state.questionnaireReview.savingState = LoadingState.LOADING;
			break;
		}
		case getType(referralClientActions.createQuestionnaireReview.success): {
			state.questionnaireReview.savingState = LoadingState.LOADED;
			break;
		}
		case getType(referralClientActions.createQuestionnaireReview.failure): {
			state.questionnaireReview.savingState = LoadingState.ERROR;
			break;
		}

		// update questionnaire status
		case getType(referralClientActions.updateReferralClientStatus.request): {
			state.statusUpdateDialog.savingState = LoadingState.LOADING;
			break;
		}
		case getType(referralClientActions.updateReferralClientStatus.success): {
			state.statusUpdateDialog.savingState = LoadingState.LOADED;
			break;
		}
		case getType(referralClientActions.updateReferralClientStatus.failure): {
			state.statusUpdateDialog.savingState = LoadingState.ERROR;
			break;
		}

		// create questionnaire review
		case getType(referralClientActions.updateReferralNotes.request): {
			state.referralNotes.savingState = LoadingState.LOADING;
			break;
		}
		case getType(referralClientActions.updateReferralNotes.success): {
			state.referralNotes.savingState = LoadingState.LOADED;
			break;
		}
		case getType(referralClientActions.updateReferralNotes.failure): {
			state.referralNotes.savingState = LoadingState.ERROR;
			break;
		}

		// open status dialog
		case getType(referralClientActions.openStatusDialog): {
			state.statusUpdateDialog.isOpen = true;
			state.statusUpdateDialog.id = action.payload.id;
			state.statusUpdateDialog.type = action.payload.status;
			state.statusUpdateDialog.notes = action.payload.notes;
			break;
		}

		// close status dialog
		case getType(referralClientActions.closeStatusDialog): {
			state.statusUpdateDialog.isOpen = false;
			state.statusUpdateDialog.savingState = LoadingState.EMPTY;
			state.statusUpdateDialog.id = null;
			state.statusUpdateDialog.type = null;
			break;
		}

		// open notes dialog
		case getType(referralClientActions.openNotesDialog): {
			state.referralNotes.isOpen = true;
			state.referralNotes.id = action.payload.id;
			state.referralNotes.notes = action.payload.notes;
			break;
		}

		// close notes dialog
		case getType(referralClientActions.closeNotesDialog): {
			state.referralNotes.isOpen = false;
			state.referralNotes.savingState = LoadingState.EMPTY;
			state.referralNotes.id = null;
			state.referralNotes.notes = null;
			break;
		}

		// fetch referral therapy type
		case getType(referralClientActions.fetchReferralTherapyType.request): {
			state.therapyType.fetchingState = LoadingState.LOADING;
			break;
		}
		case getType(referralClientActions.fetchReferralTherapyType.success): {
			state.therapyType.fetchingState = LoadingState.LOADED;
			state.therapyType.data = action.payload.data;
			break;
		}
		case getType(referralClientActions.fetchReferralTherapyType.failure): {
			state.therapyType.fetchingState = LoadingState.ERROR;
			break;
		}

		// reset review template
		case getType(referralClientActions.resetQuestionnaireReviewState): {
			state.reviewTemplate.fetchingState = LoadingState.EMPTY;
			state.questionnaireReview.savingState = LoadingState.EMPTY;
			break;
		}
	}
}, defaultState);
