import {OutgoingCallType} from "@sense-os/goalie-js";
import {createAction, ActionType} from "typesafe-actions";

export const startCallScreenActions = {
	// Open start call dialog action
	openCallScreen: createAction("startCallScreen/openCallScreen", (isVideo: boolean, isAudio: boolean) => ({
		isVideo,
		isAudio,
	}))(),

	// Close start call dialog action
	closeCallScreen: createAction("startCallScreen/closeCallScreen")(),

	// Set video call call type action
	setVideoCallType: createAction("startCallScreen/setVideoCallType")(),

	// Set audio call call type action
	setAudioCallType: createAction("startCallScreen/setAudioCallType")(),

	// Set outgoing call type action
	setOutgoingCallType: createAction("startCallScreen/setOutgoingCallType", (selectedCallType: OutgoingCallType) => ({
		selectedCallType,
	}))(),

	// Start or initiate call action
	initiateCall: createAction("startCallScreen/initiateCall")(),
};

export type StartCallScreenTypeActions = ActionType<typeof startCallScreenActions>;
