import {EMPTY_MESSAGE, FileItem, SelectedFile} from "./../../fileSharing/fileSharingTypes";
import {fileSharingActions} from "./../../fileSharing/redux/fileSharingActions";
import {call, takeEvery, select, put, all} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";
import chatSDK from "../sdk";
import {Message} from "@sense-os/goalie-js";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {toastActions} from "../../toaster/redux";
import {Contact} from "../../contacts/contactTypes";
import {uploadFilesHandler} from "../../fileSharing/sagas/uploadFilesSaga";
import {fileChatToUploadRequest} from "../../fileSharing/helpers/fileSharingHelper";
import strTranslation from "../../assets/lang/strings";
import {LoadingState} from "constants/redux";

const log = createLogger("sendMessageSaga", SentryTags.Chat);
function* sendMessageHandler(action: ActionType<typeof ChatRoomAction.sendTextMessage>) {
	const {
		userId,
		additionalContents: {files, replyMessageId, meetingInfo},
	} = action.payload;

	// Clears any selected message if any
	yield put(ChatRoomAction.setSelectedMessage(userId, null));
	// Clears any selected files if any
	yield put(fileSharingActions.setSelectedFile(userId, []));
	try {
		// if user attach images with documents,
		// we need to separate each document as one message.
		let sentMessages: Message[] = [];
		let documents: FileItem[] = [];
		let images: FileItem[] = [];

		if (files?.length > 0) {
			// Set uploading start
			yield put(ChatRoomAction.setChatRoomUploadingState(userId, LoadingState.LOADING));

			const results = yield call(
				uploadFilesHandler,
				userId,
				files.map((file: SelectedFile) => fileChatToUploadRequest(file, userId, action.payload.text)),
			);

			const hasErrors = results.filter((result) => !result.success).length > 0;
			// if there's any error related to upload file progress then abort the send message.
			// this decision is based on the send message behaviour it self which has no retry feature yet.
			// User story to handle the retry message / upload is here:
			// https://niceday.productboard.com/feature-board/454236-progress-by-status/features/11405009/detail
			if (hasErrors) {
				return;
			}

			results.forEach(({data}) => {
				// split between documents and images
				if (/.(pdf)$/.test(data?.name.toLowerCase())) {
					documents.push(data);
				} else {
					images.push(data);
				}
			});
		}

		// send document files as one message each.
		if (documents.length) {
			const callSentMessageWithDocuments = documents.map((document) => {
				return call(chatSDK.sendTextMessage, userId, EMPTY_MESSAGE, {
					replyOfId: replyMessageId,
					attachmentIds: [document.id],
				});
			});

			sentMessages = yield all(callSentMessageWithDocuments);
		}

		const onlyUploadDocumentsNoText =
			action.payload.text === EMPTY_MESSAGE && images.length === 0 && documents.length > 0;
		const uploadDocumentWithText =
			action.payload.text !== EMPTY_MESSAGE && images.length === 0 && documents.length > 0;
		const uploadImages = (images || []).length > 0;
		const sendMessageOnly = (files || []).length === 0;
		const sendMeetingInfoOnly = !!meetingInfo;

		let sentMessageImages: Message;
		if (
			!onlyUploadDocumentsNoText &&
			(uploadImages || sendMessageOnly || uploadDocumentWithText || sendMeetingInfoOnly)
		) {
			// if there's images send them in one message.
			sentMessageImages = yield call(chatSDK.sendTextMessage, userId, action.payload.text, {
				replyOfId: replyMessageId,
				attachmentIds: images.map((imageFile) => imageFile.id),
				meetingInfo: meetingInfo,
			});

			sentMessages.push(sentMessageImages);
		}

		// Set uploading success
		yield put(ChatRoomAction.setChatRoomUploadingState(userId, LoadingState.LOADED));
		// Add sent messages into redux state
		yield put(ChatRoomAction.addMessages(userId, sentMessages));
		// Set input text to empty string again
		yield put(ChatRoomAction.setInputText(userId, ""));
		// Save sent message to backend
		yield put(ChatRoomAction.setLastSentTime(userId, sentMessages[sentMessages.length - 1].archiveId));

		log.debug("Message sent", sentMessages);
	} catch (err) {
		log.captureException(err, {message: "Could not send your mssage"});
		const contact: Contact = yield select((state) => getContactById(state, userId));

		if (files?.length > 0) {
			// Set uploading failed
			yield put(ChatRoomAction.setChatRoomUploadingState(userId, LoadingState.ERROR));
		}
		yield put(
			toastActions.addToast({
				message: strTranslation.CHAT.send_message.fail,
				type: "error",
				localizationPayload: {
					name: contact.firstName + " " + contact.lastName,
				},
			}),
		);
	}
}

export default function* () {
	yield takeEvery(getType(ChatRoomAction.sendTextMessage), sendMessageHandler);
}
