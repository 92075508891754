import {Message} from "@sense-os/goalie-js";
import {eventChannel} from "redux-saga";
import {call, cancelled, put, select, take} from "redux-saga/effects";
import {ChatRoomAction} from "../redux/ChatRoomAction";
import {getAuthUser} from "../../auth/redux";
import {contactActions} from "../../contacts/redux/contactAction";
import {getContactById} from "../../contacts/redux/contactSelectors";
import {SentryTags} from "../../errorHandler/createSentryReport";
import createLogger from "../../logger/createLogger";
import chatSDK from "../sdk";
import {NotificationAction} from "../../notifications/redux/NotificationAction";
import featureFlags from "../../featureFlags/FeatureFlags";
import {agoraActions} from "../../agoraMeeting/redux/agoraActions";
import {getAgoraUrl} from "@sense-os/goalie-js/dist/commons/ServerConfig";

const log = createLogger("incomingMessageSaga", SentryTags.Chat);

const createIncomingMessageChannel = () => {
	return eventChannel<Message>((emitter) => {
		const subscriptionId = chatSDK.subscribeToIncomingMessage(emitter);
		return () => {
			chatSDK.unsubscribeFromIncomingMessage(subscriptionId);
		};
	});
};

export function* incomingMessageSubscriptionHandler() {
	const channel = createIncomingMessageChannel();
	try {
		while (true) {
			const message: Message = yield take(channel);
			yield call(incomingMessageHandler, message);
		}
	} finally {
		if (yield cancelled()) {
			channel.close();
		}
	}
}

/**
 * Reroute incoming messages into chat rooms in redux state.
 */
export function* incomingMessageHandler(message: Message) {
	log.debug("msg from: ", message.from);

	const authUser = yield select(getAuthUser);
	// Message sent from another instance by authUser
	const isCarbonCopy: boolean = authUser.id === message.from;
	if (isCarbonCopy) {
		yield put(ChatRoomAction.addMessages(message.to, [message]));
		return;
	}

	// Sender's contact data
	const senderContactData = yield select((state) => getContactById(state, message.from));

	// True if message was sent from an unidentified sender
	const unidentifiedSender = !senderContactData;
	if (unidentifiedSender) {
		// Try to load contact, which will also load all chats from the sender
		yield put(contactActions.loadContactById(message.from));
		return;
	}

	// Add message to redux state
	yield put(ChatRoomAction.addMessages(message.from, [message]));

	// Here we want to show `AgoraInvitationDialog` when `authUser` received chat message
	// that includes Agora invitation link
	// TODO Remove decoding after Backend support Agora link convertion for mobile apps push notification
	const trimmedAgoraBaseUrl: string = getAgoraUrl().replace(/(^\w+:|^)\/\/|(\/)+/g, ""); // get only `meeting-ag-alpha.niceday.dev`
	const isAgoraInviteLink: boolean = message.content.TEXT.includes(trimmedAgoraBaseUrl);
	if (featureFlags.meetingAgora && isAgoraInviteLink) {
		const decodedInviteLink: string = decodeURIComponent(message.content.TEXT);
		yield put(agoraActions.openInvitationDialog(message.from, decodedInviteLink));
	}

	if (featureFlags.meetingAgora && featureFlags.meetingAgoraSdk && !!message.meetingInfo) {
		const {id, callType} = message.meetingInfo;
		yield put(agoraActions.openInvitationDialog(message.from, undefined, id, callType));
	}

	// If message is a TEXT message (Not a call summary) try to show browser's notification
	const isTextMessage = message.content.TEXT && Object.keys(message.content).length === 1;
	if (isTextMessage) {
		let messageText: string = message.content.TEXT;
		yield put(NotificationAction.onReceivedChatMessage(message.from, messageText));
	}
}
